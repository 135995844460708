<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      width="120"
      min-height="140"
    >
      <v-icon
        color="primary"
        size="30"
        class="file-icon-position"
      >
        mdi-file-hidden
      </v-icon>
      <div
        v-if="options.inQuestionnaire"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="requiredDoc.name.length >= 32"
              v-bind="attrs"
              v-on="on"
            >
              <p
                class="black--text font-weight-thin text-position"
                v-text="requiredDoc.name.slice(0, 32) + '...'"
              />
            </div>
          </template>
          <span>{{ requiredDoc.name }}</span>
        </v-tooltip>
      </div>
      <div v-if="options.inQuestionnaire && requiredDoc.name.length < 32">
        <p
          class="black--text font-weight-thin text-position"
          v-text="requiredDoc.name"
        />
      </div>
      <div
        v-if="options.inQuestionnaire"
        class="field-tag-add-box"
        :class="{'used': requiredDoc.inUse}"
        @click="$emit('useFieldInQuestionnaire')"
      >
        <template v-if="suggestionsInUseInQuestionnaire.includes(requiredDoc.name) === false">
          <v-icon
            small
            color="white"
          >
            mdi-plus
          </v-icon>
          Use
        </template>
        <template v-else>
          {{ $t('common|in_use') }}
        </template>
      </div>
      <div
        v-if="options.inWorkbench"
      >
        <v-icon
          right
          small
          class="x-icon-position"
          color="primary"
          @click="$emit('emitSuggestionToBeDeleted') && $emit('emitSuggestionToBeDeletedInQuestionnaire')"
        >
          mdi-close
        </v-icon>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="requiredDoc.name.length >= 32"
              v-bind="attrs"
              v-on="on"
            >
              <p
                class="black--text font-weight-thin text-position"
                v-text="requiredDoc.name.slice(0, 32) + '...'"
              />
            </div>
          </template>
          <span>{{ requiredDoc.name }}</span>
        </v-tooltip>
      </div>
      <div v-if="options.inWorkbench && requiredDoc.name.length < 32">
        <p
          class="black--text font-weight-thin text-position"
          v-text="requiredDoc.name"
        />
      </div>
      <v-tooltip
        top
      >
        <template
          v-slot:activator="{ on, attrs }"
        >
          <v-icon
            v-if="suggestionsInUseInQuestionnaire.includes(requiredDoc.name)"
            color="primary"
            v-bind="attrs"
            class="position-q-in-use"
            v-on="on"
          >
            mdi-comment-question-outline
          </v-icon>
        </template>
        <span>{{ $t('questionnaire|used_in_questionnaire') }}</span>
      </v-tooltip>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  props: {
    requiredDoc: {
      type: Object,
      default: null
    },
    suggestionsInUseInQuestionnaire: {
      type: Array,
      default: null
    },
    options: {
      type: Object,
      default: () => ({
        inQuestionnaire: false,
        inWorkbench: false
      })
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  .field-tags-container {
    display: flex;
    flex-wrap: wrap;
    .field-tag-add-box {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 3px;
      margin: .3rem;
      overflow: hidden;
      font-size: .7rem;
      position:absolute;
      bottom: 3px;
      left: 0;
      > div {
        display: flex;
        align-items: center;
        padding: 2px 3px;
        &:nth-of-type(2) {
          background-color: #2a9b9b;
          color: white;
          cursor: pointer;
          &:hover {
            background-color: #5694d6;
          }
        }
      }
      &.used {
        > div {
          &:nth-of-type(2) {
            background-color: #2a9b9b;
            cursor: auto;
          }
        }
      }
    }
  }
  .ghost-card {
    opacity: 0.5;
    background: #F7FAFC;
    border: 1px solid #4299e1;
  }
  .x-icon-position {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .file-icon-position {
    position: absolute;
    top: 15px;
    left: 40px;
  }
  .text-position {
    position: absolute;
    top: 50px;
    text-align: center;
  }
  .position-q-in-use {
    position: absolute;
    bottom: 3px;
    right: 5px;
  }
</style>
