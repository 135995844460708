<template>
  <v-card
    height="100%"
    color="grey lighten-5"
    outlined
  >
    <v-card-title>
      <h5>{{ $t('documents|docs_required_for') }} {{ workbench.name }}</h5>
      <v-btn
        v-if="selection === 'table'"
        color="primary"
        class="button-position"
        text
        dark
        small
        @click="viewIsSelected('cards')"
      >
        {{ $t('common|cards') }}
        <v-icon
          small
          class="ml-2"
        >
          mdi-cards-variant
        </v-icon>
      </v-btn>
      <v-btn
        v-if="selection === 'cards'"
        color="primary"
        class="button-position"
        text
        dark
        small
        @click="viewIsSelected('table')"
      >
        Table
        <v-icon
          small
          class="ml-2"
        >
          mdi-table-large
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider
      class="ml-4"
    />
    <v-card-text>
      <div v-if="selection === 'table'">
        <v-list
          dense
          class="virtual-scroll-list"
        >
          <v-list-item
            v-for="s in uniqueDocumentSuggestions"
            :key="s.id"
          >
            <v-list-item-icon>
              <v-icon
                color="primary"
              >
                mdi-file-hidden
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-if="s.name === null"
              >
                {{ $t('documents|empy_document_field') }}
              </v-list-item-title>
              <v-list-item-title
                :value="s.key"
                class="pl-n4"
                v-text="s.name"
              />
            </v-list-item-content>
            <v-tooltip
              top
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-icon
                  v-if="suggestionsInUseInQuestionnaire.includes(s.name) === true"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-comment-question-outline
                </v-icon>
              </template>
              <span>{{ $t('questionnaire|used_in_questionnaire') }}</span>
            </v-tooltip>
            <v-icon
              right
              small
              class="ml-1"
              color="primary"
              @click="emitSuggestionToBeDeleted(s); emitSuggestionToBeDeletedInQuestionnaire(s)"
            >
              mdi-close
            </v-icon>
          </v-list-item>
        </v-list>
      </div>
      <div
        v-else
        class="virtual-scroll-list row pa-2"
      >
        <RequiredDocumentsCard
          v-for="s in uniqueDocumentSuggestions"
          :key="s.id"
          :required-doc="s"
          :suggestions-in-use-in-questionnaire="suggestionsInUseInQuestionnaire"
          :options="{
            inQuestionnaire: false,
            inWorkbench: true
          }"
          class="ma-2"
          @emitSuggestionToBeDeleted="emitSuggestionToBeDeleted(s)"
          @emitSuggestionToBeDeletedInQuestionnaire="emitSuggestionToBeDeletedInQuestionnaire(s)"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import RequiredDocumentsCard from './RequiredDocumentsCard.vue'

export default {
  components: { RequiredDocumentsCard },
  props: {
    currentCaseForm: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      selection: localStorage.getItem('preferedViewRequiredDocs') || 'cards'
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      caseForms: state => state.caseForms.caseForms
    }),
    allTemplatesInCaseForm () {
      return this.currentCaseForm.workbenches.reduce((acc, w) => {
        acc.push(
          ...w.templates
        )
        return acc
      }, [])
    },
    suggestionsInUseInQuestionnaire () {
      let newFields = []
      for (let i = 0; i < this.currentCaseForm.workbenches.length; i++) {
        const workbench = this.currentCaseForm.workbenches[i]
        for (let i = 0; i < workbench.templates.length; i++) {
          const template = workbench.templates[i]
          const fields = template.questionnaire.fields
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i]
            let fieldName = field.name
            newFields.push(fieldName)
          }
        }
      }
      return newFields
    },
    filteredSuggestionsFromQuestionnaire () {
      return this.workbench.templates.reduce((acc, s) => {
        acc.push(
          ...s.questionnaire.fields
        )
        return acc
      }, [])
    },
    filteredSuggestions () {
      return Object.values(this.workbench.requiredDocuments).map((s) => {
        return {
          ...s,
          name: s.name
        }
      })
    },
    allSuggestions () {
      const suggestions = this.filteredSuggestionsFromQuestionnaire.concat(this.filteredSuggestions)
      return suggestions
    },
    uniqueSuggestions () {
      return this.allSuggestions.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.key]: current
        })
      }, {})
    },
    uniqueDocumentSuggestions () {
      const suggestions = Object.keys(this.uniqueSuggestions).reduce((acc, key) => {
        const item = this.uniqueSuggestions[key]
        if (item.fieldType === 'file-input') {
          acc[key] = item
        }
        return acc
      }, {})
      return suggestions
    }
  },
  methods: {
    ...mapActions({
      deleteCaseModelRequiredDocument: 'caseForms/deleteCaseModelRequiredDocument'
    }),
    emitSuggestionToBeDeleted (s) {
      EventBus.$emit('suggestion-to-delete', s)
    },
    emitSuggestionToBeDeletedInQuestionnaire (s) {
      EventBus.$emit('suggestion-to-delete-in-q', s)
    },
    viewIsSelected (value) {
      this.selection = value
      localStorage.setItem('preferedViewRequiredDocs', value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .virtual-scroll-list {
    // max-height: 640px;
    overflow: auto;
  }
  .button-position {
    position: absolute;
    top: 5px;
    right: 5px;
  }
</style>
